import React, { useEffect, useRef, useState } from "react"
import { Container } from "react-bootstrap"
import Layout from "../components/layout"
import {
  createURL,
  getH1Text,
  INDEX_NAME,
  searchStateToUrl,
  SEARCH_CLIENT,
  urlToSearchState,
  getSeoDesc,
  getSeoH1Text,
} from "../components/SearchResults/utils"
import StatAndSort from "../components/SearchResults/StatAndSort"
import { Link, navigate } from "gatsby"
import SEO from "../components/seo"
import { Configure, InstantSearch } from "react-instantsearch-dom"
import "../components/SearchResults/AuctionResults.scss"
import CustomAuctionsInfiniteHits from "../components/SearchResults/CustomAuctionsInfiniteHits"
import SearchPagination from "../components/SearchResults/SearchPagination"
import useDeviceMedia from "../hooks/useDeviceMedia"
import CustomNoHitsAuctions from "../components/SearchResults/CustomNoHitsAuctions"
import { contact, homeValuation, instantValuation } from "../site/urls"
import CustomSeoStructure from "../components/Seo/SeoStructuredData"

const SearchResultsAuctionTemplate = props => {
  const { location, pType, pStatus, pCategoryType, locationName, pSubPath } =
    props
  const [searchState, setSearchState] = useState(
    urlToSearchState(location, pSubPath)
  )
  const debouncedSetStateRef = useRef(null)
  const [showDescription, setShowDescription]=useState(false)

  useEffect(() => {
    setSearchState(urlToSearchState(location, pSubPath))
  }, [location])

  const onSearchStateChange = searchState => {
    clearTimeout(debouncedSetStateRef.current)
    debouncedSetStateRef.current = setTimeout(() => {
      navigate(searchStateToUrl(props, searchState, locationName), searchState)
    }, 500)
    setSearchState(searchState)
  }

  //   const h1Text = getH1Text({ searchState, pType, capitalize: true })
  // const h1Text = "Auction properties for sale"

  // let pCategoryType1="residential" //-dummy category have to remove
  const {isMobile}=useDeviceMedia()

  let mystatus = ''
    if(pStatus){
      if(Array.isArray(pStatus)){
        mystatus = '(status:"'+(pStatus).join('" OR status:"')+'")'
      } else {
        mystatus = '(status:"'+(pStatus)+'")'
      }
    }

    const h1Text = getH1Text({ searchState,pSubPath, pType,pCategoryType,capitalize: true })
    const infoText = getH1Text({ searchState,pSubPath, pType,pCategoryType, capitalize: false })
    const seoDescription = getSeoDesc({ searchState,pSubPath, pType,pCategoryType,capitalize: false })
    const seoTitle=getSeoH1Text({ searchState,pSubPath, pType,pCategoryType,capitalize:true })

  
    let finalInfoText = (
      <>
        Explore our range of {infoText}. For more information about <span className="dynamic-desc-bold">off market properties</span><Link to={contact}>, please contact us</Link>
      </>
    )


  return (
    <>
        <div className="bottom-right-image-home">
        <div className="hover-block">
          <div className="block-elements">
           {/* <Link to={instantValuation}>Instant Online Valuation</Link>
           <div className="vertical-line"></div> */}
           <Link to={homeValuation}>Home Visit Valuation</Link>
           </div>
        </div>
        <div className="banner-img"></div>
     </div>
      <SEO title={seoTitle} description={seoDescription}/>
      
      <InstantSearch
        indexName={INDEX_NAME}
        searchClient={SEARCH_CLIENT}
        searchState={searchState}
        onSearchStateChange={onSearchStateChange}
        createURL={createURL}
        routing="true"
      >
        <Configure
          hitsPerPage={18}
          page={0}
          filters={`publish:true AND search_type:${pType} AND department:${pCategoryType} AND ${mystatus}`}
        />
        <CustomSeoStructure
          name={seoTitle}
          description={seoDescription}
          pType={pType}
          type="SearchResultsPage"
          publisher={{ name: "Olivers Town Estate Agents", logo: "https://ggfx-oliverstown.s3.eu-west-2.amazonaws.com/i.prod/logo_a1747dfb98.png" }}
        />
        <Layout>
          <div className="auction-search-result">
            <Container>
              <div className="auction-result-sections">
                <div className="auction-search-result-heading">
                  <div className="dynamic-title">
                    <h1>{h1Text}</h1> <icon className="icon info-icon" onClick={()=>setShowDescription(!showDescription)}/>
                  </div>

                  {!isMobile&&
                  <div className="sort-map-container">
                    <StatAndSort pCategoryType={pCategoryType} />
                  </div>
                 } 
                </div>
                {showDescription&& 
                  <p className="dynamic-description">{finalInfoText}</p>
                   }

                <div className="search-list-section">
                  <>
                    <CustomAuctionsInfiniteHits />
                    <CustomNoHitsAuctions
                    status={pStatus}
                    department={pCategoryType}
                    search_type={pType}
                  />
                    <SearchPagination />
                  </>
                </div>
              </div>
            </Container>
          </div>
        </Layout>
      </InstantSearch>
    </>
  )
}

export default SearchResultsAuctionTemplate
