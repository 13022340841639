import { Link } from "gatsby"
import React from "react"
import { ShowProcessedImage } from "../../common/ggfx-client/module/components/show-image"
import ImageModule from "../../modules/image-render"
import imageConfig from "../../../static/images/config.json"
import { numberFormat } from "../Common/site/utils"
import { auctionPropertyDetailURL, propertyForRentDetailURL, propertyForSaleDetailURL } from "../../site/urls"


const SearchResultCardAuction = ({
  title,
  address,
  slug,
  image,
  id,
  price,
  imagetransforms,
  status
}) => {

  let processedImages = imagetransforms?.images_Transforms
  processedImages ||= JSON.stringify({})
  const pathURL =auctionPropertyDetailURL

  return (
    <div className="property-search-card-list">
      <Link to={pathURL + slug + "-" + id}>
         <ShowProcessedImage
              images={image}
              attr={{
                className: "img-fluid",
                alt: title ,
              }}
              transforms={imageConfig.property.searchResults.sizes}
            />
            {status==="Sold"&&
            <div className="status-flag">
              <p>{status}</p>
            </div>
            }
      </Link>

      <div className="property-data">
        <Link to={pathURL + slug + "-" + id}>
          <p className="display-address">{address}</p>
        </Link>
        <p className="price">Starting Bid £{numberFormat(price)}</p>
        <p className="property-title">{title}</p>
      </div>
    </div>
  )
}

export default SearchResultCardAuction
