import _ from "lodash"
import React from "react"
import { connectHits } from "react-instantsearch-dom"
import SearchResultCardAuction from "../SearchResultCardAuction/SearchResultCardAuction"

const InfiniteHits = ({ hits }) => {

  if (_.isEmpty(hits)) return null

  return (
    <div className="search-list-items">
      {hits.map(hit => (
        <SearchResultCardAuction
          key={hit.objectID}
          title={hit.title}
          address={hit.display_address}
          description={hit.description}
          status={hit.status}
          slug={hit.slug}
          image={hit.images[0]}
          id={hit.crm_id}
          price={hit.price}
          search_type={hit.search_type}
          department={hit.department}
        />
      ))}
    </div>
  )
}

const CustomAuctionInfiniteHits = connectHits(InfiniteHits)

export default CustomAuctionInfiniteHits
