import React from "react"
import {  connectStats } from "react-instantsearch-dom"
import _ from "lodash"
import SearchResultCardAuction from "../SearchResultCardAuction/SearchResultCardAuction"
import { graphql, useStaticQuery } from "gatsby"

const NoHits = ({ status, department, search_type,nbHits }) => {
    const data = useStaticQuery(graphql`
    query {
        allStrapiProperty(filter: {publish: {eq: true}}, limit: 6) {
            edges {
                node {
                    id
                    slug
                    crm_id
                    strapi_id
                    images {
                        strapi_json_value {
                            url
                        }
                    }
                    price_qualifier
                    price
                    search_type
                    title
                    display_address
                    imagetransforms {
                        images_Transforms
                    }
                }
            }
        }
    }
`);


  const properties = data.allStrapiProperty.edges;

  if (nbHits!==0) return null

  return (
    <div className="no-hits-section">
      <h3 className="no-hit-title">
        Unfortunately, we do not currently have any properties that match your
        search criteria.
      </h3>
      <p className="description">
        We have selected some of our showcase properties for you to browse
        below.
      </p>
      {/* <div className="search-results-list-wrapper">
        {properties &&
          properties.map(hit => (
            <SearchResultCardAuction
            key={hit.objectID}
            title={hit.title}
            address={hit.display_address}
            description={hit.description}
            status={hit.status}
            slug={hit.slug}
            image={hit.images?.length>0&&hit.images[0]}
            id={hit.objectID}
            price={hit.price}
            />
          ))}
      </div> */}
    </div>
  )
}

const CustomNoHitsAuctions = connectStats(NoHits)

export default CustomNoHitsAuctions
