import React from 'react'
import { auctionForSale } from '../../../site/urls'
import SearchResultsAuctionTemplate from '../../../templates/search-result-auction-template'

const PropertyAuctionSale = (props) => {
    const location = props.location
  return (
    <SearchResultsAuctionTemplate
    locationName={auctionForSale}
    location={location}
    pCategoryType="auction"
    pType="sales"
    pSubPath="for-sale"
    pStatus="For Sale"
  />
  )
}

export default PropertyAuctionSale